import {getPlaceList, Places} from "../data/api";
import {log} from "../utils/logs";

function validateBounds(bounds: string): boolean {
    return !!bounds;
}

export default async function placeListLoader({request}: any) {
    const verbose = true;
    const module = 'placeListLoader';

    const url = new URL(request.url);
    const searchParams = url.searchParams;
    log(`starting - searchParams:`, searchParams.toString(), module, 1, verbose);

    let placeFilter: { [key: string]: any } = {};

    // add bounds filter
    const bounds = url.searchParams.get("bounds");
    if (validateBounds(bounds)) placeFilter.bounds = bounds;

    // add slug filter
    const slug = url.searchParams.get('slug');
    if (slug) placeFilter.slug = slug;

    // add q filter (search)
    const q = url.searchParams.get("q");
    if (q) placeFilter.q = q;

    // only published places
    // const is_published = url.searchParams.get("is_published");
    placeFilter.published = 'true';

    log(`placeFilter`, placeFilter, module, 2, verbose);
    let places = await getPlaceList(placeFilter) as Places;

    // filter results to only places with images
    const placesWithoutImages = places.filter(place => place.images.length > 0);
    log(`placescount`, placesWithoutImages.length, module, 1, verbose);

    places = placesWithoutImages;
    log(`places`, places, module, 2, verbose);
    return {places, q, searchParams }
}
