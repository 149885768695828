import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import React from "react";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

// @ts-ignore
import icon from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L, {LatLngExpression} from "leaflet";
import {log} from "../../utils/logs";
import {MapApiCoverageController} from "./MapApiCoverageController";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function MapApiCoverage() {
    log('starting', '', 'MapApiCoverage', 1, true);

    const siechnice = [51.0328028,17.1366682] as LatLngExpression;
    const center = siechnice;
    const mapZoom = 12;

    return (
        <div className='w-full h-full'>
            <MapContainer zoom={mapZoom} center={center} scrollWheelZoom={false} attributionControl={false}>
                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png"/>
                <MapApiCoverageController />
            </MapContainer>
        </div>
    )
}
