import {Menu} from "../Menu";
import React, {useEffect} from "react";
import {useLoaderData, useLocation, useNavigation} from "react-router-dom";
import {PlaceList} from '../places/PlaceList';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";
import {Search2} from '../search/SearchPlacesOld';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import PlaceRecommendation from "../places/PlaceRecommendation";
import {Outlet} from "react-router-dom";
import Map from '../map/Map'
import {useParams} from "react-router-dom";
import {log} from "../../utils/logs";
import {setSelectedPlacePk} from "../../redux/place-slice";
import {ScrollRestoration} from "react-router-dom";
import type {Location, useMatches} from "react-router-dom";
import { Spinner } from "../spinner/Spinner";

export function MainLayout() {
    log('starting', '', 'MainLayout', 1, true);

    const location = useLocation();
    const dispatch = useAppDispatch();
    const params = useParams();

    useEffect(() => {
        const {placeId} = params;
        const placePk: number = parseInt(placeId);
        log('placePk ', placePk, 'MainLayout', 2, true);
        if (placePk) {
            log('location changed to ', location, 'MainLayout', 2, true);
            dispatch(setSelectedPlacePk(placePk));
        }
    }, [location]);

    const navigation = useNavigation();
    return (
        <div className='text-sm font-normal text-gray-600 antialiased'>
            <div id="page"
                 className="mx-auto flex min-h-[calc(100svh)] max-w-sm flex-col text-center bg-white shadow-md">

                {/*/!* id=menu-top - top-0, h-0 - we don't need menu-top for now *!/*/}
                {/*<div id="menu-top" className="fixed top-0 h-0 w-full max-w-sm">menu-top</div>*/}

                {/* id=map - h-48 - size of map */}
                <div id="map" className="fixed top-0 h-48 w-full max-w-sm z-50"><Map/></div>

                {/* id=content - mt-48 - leave margin top for size of map */}
                {/* id=content - mb-24 - leave margin bottom for size of menu-bottom */}
                {
                    navigation.state === "loading" ?
                        <Spinner />
                        :
                        <div id="content" className="w-full max-w-sm mt-48 mb-24 z-40"><Outlet/></div>
                }

                {/* id=menu-bottom - h-24 - leave place on bottom for size of menu-bottom  */}
                <div id='menu-bottom' className="fixed bottom-0 h-24 mx-0 w-full max-w-sm z-50 bg-white"><Menu/>
                </div>
            </div>
            {/*<div id="full-panel" className="fixed top-0 mx-auto inset-0 max-w-sm w-full min-h-[calc(100svh)] z-50 bg-white"><SearchPlaces places={places}/></div>*/}
            {/*<div id="full-panel" className={`${isActiveSearchPanel} fixed top-0 mx-auto inset-0 max-w-sm w-full min-h-[calc(100svh)] z-50 bg-white overflow-auto`}><Search2 places={places}/></div>*/}
            {/*<div id="add-recommendation" className={`${isActiveAddRecommendation}`}><PlaceRecommendation /></div>*/}
            <ScrollRestoration getKey={(location, matches) => {
                return location.pathname;
            }}
            />
        </div>
    )
}
