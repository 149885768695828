import {configureStore} from "@reduxjs/toolkit";
import mapReducer from './map-slice'
import placeReducer from './place-slice'

export const store = configureStore({
    reducer: {
        map: mapReducer,
        place: placeReducer,
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
