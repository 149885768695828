import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import React, {useEffect} from "react";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

// @ts-ignore
import icon from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import {useLoaderData, useLocation, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";
import {FlyToGpsPositionController} from "./FlyToGpsPositionController";
import PlaceMarker from "./PlaceMarker";
import CurrentPositionMarker from "./CurrentPositionMarker";
import {log} from "../../utils/logs";
import {isValidPlace, isValidPlaceForMap, Place, Places} from "../../data/api";
import {SearchLocationController} from "./SearchLocationController";
import {MoveToSelectedMarkerComponent} from "./ShowSelectedMarkerController";
import {MapBoundsController} from "./MapBoundsController";
import {MapCenterController} from "./MapCenterController";
import {MapApiCoverageController} from "./MapApiCoverageController";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Map() {
    log('starting', '', 'Map', 1, true);
    const {places} = useLoaderData() as { places: Places };

    // configureGpsPosition();
    const currentPosition = useAppSelector(state => state.map.currentLocation);
    const center = useAppSelector(state => state.map.center);
    const bounds = useAppSelector(state => state.map.bounds);
    const mapZoom = useAppSelector(state => state.map.zoom);
    const selectedPlace = useAppSelector(state => state.map.selectedPlace);

    log('map params: ', {PlacesNumber: places?.length, bounds: bounds.toString(), mapZoom: mapZoom}, 'Map', 1, true);

    const location = useLocation();

    return (
        <div className='w-full h-full'>
            <MapContainer zoom={mapZoom} bounds={bounds} scrollWheelZoom={false} attributionControl={false}>
                <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png"/>

                {/*my custom controllers*/}

                <FlyToGpsPositionController/>
                <CurrentPositionMarker/>
                <SearchLocationController/>


                <MoveToSelectedMarkerComponent/>
                <MapBoundsController/>

                <MapCenterController/>

                {
                    location.pathname.match('map-api-coverage') ?
                        // show map api coverage if /map-api-coverage url
                        <MapApiCoverageController/>
                        :
                        // otherwise show places
                        places ?
                            <div>
                                <MarkerClusterGroup maxClusterRadius={15} chunkedLoading showCoverageOnHover={true}>
                                    {places.map((place: any) => <PlaceMarker key={place.pk} place={place}/>)}
                                </MarkerClusterGroup>
                            </div>
                            : <div>no places found.</div>
                }
            </MapContainer>
        </div>
    )
}
