import {getPlaceDetails} from "../data/api";
import {Form, Link, useLoaderData, useParams} from "react-router-dom";
import {log} from "../utils/logs";
import {Place, isValidPlace} from '../data/api';
import React from "react";
import {PlaceSlider} from "../components/places/PlaceSlider";
import {
    LinkToPlaceDetails,
    LinkToPlaceList,
    LinkToPlaceListWithText,
    LinkWithSearchParams
} from "../components/navigation/LinkWithSearchParams";
import {useNavigate} from 'react-router-dom';
import { getPlaceRatingValue } from "../data/helpers";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons/faSquareTwitter";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";


function PlaceContactDetails({place}: any) {
    return (
        <>
            <section className="bg-white ">
                <div className="py-2 mx-auto max-w-screen-sm ">
                    <div className="flex">
                        <div
                            className="items-center bg-gray-50 rounded-lg shadow sm:flex w-full mb-2">
                            <div className="w-full">
                                <div className="">
                                    {
                                        place.images[0] && (
                                            <div
                                                style={{backgroundImage: `url(${place.images[0].images})`}}
                                                className='w-full h-64 rounded-t-lg bg-center bg-cover duration-500 mt-1'
                                            ></div>
                                        )
                                    }
                                    <h3 className="text-sm font-semibold leading-7 text-sky-700 my-2">{place.name}</h3>
                                    <blockquote className="relative">
                                        <div className="relative z-10">
                                            <p className="text-gray-800 dark:text-white text-xs text-balance"><em>
                                                {place.description}
                                            </em></p>
                                        </div>
                                    </blockquote>
                                </div>

                                <ul className="flex justify-center space-x-4 my-4">
                                    <li><FontAwesomeIcon icon={faInstagram} size='2xl' /></li>
                                    <li><FontAwesomeIcon icon={faFacebookSquare} size='2xl' /></li>
                                    <FontAwesomeIcon icon={faSquareTwitter} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

function Review({rating}: any) {
    return (
        <div className="flex items-center">
            <svg className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 22 20">
                <path
                    d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
            </svg>
            średnia ocena: <p className="ms-2 text-sm font-bold text-gray-900 dark:text-white">{rating}</p>
            {/*<span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>*/}
            {/*<a href="#" className="text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white">73*/}
            {/*    reviews</a>*/}
        </div>

    )
}

function FacilityItem(props: any) {
    return (
        props.enabled ?
            <li className="flex items-center">
                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                {props.children}
            </li>

            :
            <li className="flex items-center">
                <svg className="w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                {props.children}
            </li>

    )
}

function Facilities({place}: any) {
    if (!place) {
        return <></>
    }
    return (
        <div>
            <div className='pl-2'>
                <h3 className="text-xs font-bold leading-6 text-gray-900 text-left">Udogodnienia lokalu</h3>
                <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">

                    <FacilityItem enabled={place.is_for_children}>przyjazne dzieciom</FacilityItem>
                    <FacilityItem enabled={place.is_for_dogs}>akceptuje zwierzęta</FacilityItem>
                    <FacilityItem enabled={place.is_for_groups}>akceptuje grupy zorganizowane</FacilityItem>
                    <FacilityItem enabled={place.provide_reservation}>mozliwa rezerwacja miejsca</FacilityItem>
                    <FacilityItem enabled={place.provide_food}>miejsce posiada jedzenie</FacilityItem>
                    <FacilityItem enabled={place.allow_credit_cards}>płatność kartą</FacilityItem>
                    <FacilityItem enabled={place.allow_cash_only}>płatność tylko gotówką</FacilityItem>
                    <FacilityItem enabled={place.provide_outdoor_seating}>stoliki na zewnątrz</FacilityItem>
                    <FacilityItem enabled={place.provide_live_music}>muzyka na zywo</FacilityItem>
                    <FacilityItem enabled={place.is_for_tv}>transmisje na zywo</FacilityItem>
                </ul>
            </div>
            <div className='pl-2'>
                <h3 className="text-xs font-bold leading-6 text-gray-900 text-left">Udogodnienia zywieniowe</h3>
                <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
                    <FacilityItem enabled={place.provide_reservation}>mozliwa rezerwacja miejsca</FacilityItem>
                    <FacilityItem enabled={place.provide_food}>miejsce posiada jedzenie</FacilityItem>
                    <FacilityItem enabled={place.provide_children_menu}>posiada menu dla dzieci</FacilityItem>
                    <FacilityItem enabled={place.provide_breakfast}>serwuje sniadanie</FacilityItem>
                    <FacilityItem enabled={place.provide_brunch}>serwuje późne śniadanie</FacilityItem>
                    <FacilityItem enabled={place.provide_lunch}>serwuje obiad</FacilityItem>
                    <FacilityItem enabled={place.provide_diner}>serwuje kolacje</FacilityItem>
                    <FacilityItem enabled={place.provide_dessert}>serwuje desery</FacilityItem>
                    <FacilityItem enabled={place.provide_cocktails}>serwuje drinki</FacilityItem>
                    <FacilityItem enabled={place.provide_takeout}>jedzenie na wynos</FacilityItem>
                    <FacilityItem enabled={place.provide_delivery}>jedzenie na dostawę</FacilityItem>
                    <FacilityItem enabled={place.provide_outdoor_seating}>stoliki na zewnątrz</FacilityItem>
                    <FacilityItem enabled={place.provide_live_music}>muzyka na zywo</FacilityItem>
                </ul>
            </div>

            <div className='pl-2'>
                <h3 className="text-xs font-bold leading-6 text-gray-900 text-left">Udogodnienia dla
                    niepełnosprawnych</h3>
                <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
                    <FacilityItem enabled={place.provide_wheelchair_seating}>miejsce dla osób
                        niepełnosprawnych</FacilityItem>
                    <FacilityItem enabled={place.provide_wheelchair_restroom}>toaleta dla osób
                        niepełnosprawnych</FacilityItem>
                    <FacilityItem enabled={place.provide_wheelchair_entrance}>wejście dla osób
                        niepełnosprawnych</FacilityItem>
                    <FacilityItem enabled={place.provide_wheelchair_parking}>parking dla osób
                        niepełnosprawnych</FacilityItem>
                </ul>
            </div>
        </div>
    )
}

function PlaceAddress({place}: any) {
    return (
        <div className='mt-1 break-all '>
            <div className="mt-2 border-t border-gray-100 text-left p-2">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-0 sm:px-0">
                        <dt className="text-xs font-bold leading-6 text-gray-900">Dane kontaktowe</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <div className='flex flex-row justify-left items-center'><span
                                className='ml-2 my-1'>{place.address}</span></div>
                            <div className='flex flex-row justify-left items-center'>

                                <span className='ml-2 my-1'>+48 {place.phone}</span></div>
                            <div className='flex flex-row justify-left items-center'>
                                <li><FontAwesomeIcon icon={faGlobe} size='sm' /></li>
                                <span
                                className='ml-2 my-1'>{place.website}</span></div>
                        </dd>
                    </div>
                    <PlaceTags place={place}/>

                    <div className="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-0 sm:px-0">
                        <dt className="text-xs font-bold leading-6 text-gray-900">Recenzje</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">To miejsce nie ma
                            jeszcze dodanych recenzji
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

function PlaceTags({place}: any) {
    return (
        <div className="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-0 sm:px-0">
            <div className="text-xs font-bold leading-6 text-gray-900">Tagi</div>
            <div className="flex flex-wrap mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row">
                {
                    place.tags.map((tag: any, index: number) =>
                        <div className='text-amber-600 text-xs font-bold p-1' key={`costam-${index}`}>
                            {tag}
                            {/*<button*/}
                            {/*    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">*/}
                            {/*    {tag}*/}
                            {/*</button>*/}
                        </div>)
                }
            </div>
        </div>
    )
}

function PlaceDetailsContent({place}: any) {
    let slides: { url: any; }[] = [];
    place.images?.map((image: { images: any; }) => slides.push({url: image.images}))

    return (
        // <div id='place' className='text-sm leading-7 antialiased text-gray-600'>
        <div id='place' className='' key={'place-' + place.pk}>
            <LinkToPlaceListWithText/>
            <hr className="h-px my-1 bg-gray-100 border-0"/>
            <PlaceContactDetails place={place}/>
            <Review rating={getPlaceRatingValue(place.rating)}/>
            <PlaceAddress place={place}/>
            <Facilities place={place}/>
            {
                slides.map((slide, index) => (
                        <div
                            key={index}
                            style={{backgroundImage: `url(${slide.url})`}}
                            className='w-full h-64 rounded-t-lg bg-center bg-cover duration-500 mt-1'
                        ></div>

                    )
                )
            }
        </div>

    )
}

export default function PlaceDetails() {

    const verbose = true;
    const module = 'PlaceDetails'
    log(`starting PlaceDetails component`, '', module, 1, verbose);


    const {place, searchParams}: any = useLoaderData();
    log(`loader data: place:`, {pk: place.pk, name: place.name}, module, 1, verbose);

    return (
        isValidPlace(place) ?
            // source: https://flowbite.com/blocks/application/table-headers/#default-table-header
            <section className="bg-gray-50 dark:bg-gray-900 min-h-[calc(100svh)] flex items-top">
                <div className="px-4 mx-auto w-full">

                    {/*start*/}
                    <div className="relative bg-white shadow-md dark:bg-gray-800 rounded-lg mt-4">
                        <div
                            className="flex flex-col items-center justify-between p-4 space-y-3">
                            <div className="w-full">

                                <PlaceDetailsContent place={place}/>
                                <LinkToPlaceListWithText/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            :
            <div>nie znalazłem miejsca</div>
    )
}
