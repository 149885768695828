import {log} from "../utils/logs";
import {getPlaceDetails, Place} from "../data/api";

export async function placeDetailsLoader({params, request}: any) {
    const verbose = true;
    const url = new URL(request.url);
    const searchParams = url.searchParams;

    log(`starting placeLoader with request`, url, 'placeLoader', 1, verbose);
    log(`starting placeLoader with searchParams`, searchParams.toString(), 'placeLoader', 1, verbose);
    log(`starting placeLoader with id`, params, 'placeLoader', 1, verbose);
    const place: Place | undefined = await getPlaceDetails(params.placeId, true);
    log(`returning: place`, place, 'placeLoader', 1, verbose);
    return {place, searchParams }
}
