import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Place, Places} from "../data/api";


export interface PlaceState {
    places: Places | undefined;
    selectedPlace: Place | undefined;
    selectedPlacePk: number | undefined;
    favouritePlaces: number[];
}

const initialState: PlaceState = {
    places: undefined,
    selectedPlace: undefined,
    selectedPlacePk: undefined,
    favouritePlaces: JSON.parse(localStorage.getItem('favouritePlaces')) || [],
}

const placeSlice = createSlice({
    name: 'place',
    initialState: initialState,
    reducers: {
        setPlaces(state, action: PayloadAction<Places>) {
            state.places = action.payload;
        },
        setSelectedPlace(state, action: PayloadAction<Place>) {
            state.selectedPlace = action.payload;
        },
        setSelectedPlacePk(state, action: PayloadAction<number>) {
            state.selectedPlacePk = action.payload;
        },
        addFavoritePlace(state, action: PayloadAction<Place>) {
            if (state.favouritePlaces?.includes(action.payload.pk)) {
                // place is already on list so remove from favourite
                const index = state.favouritePlaces.indexOf(action.payload.pk);
                if (index !== -1) {
                    state.favouritePlaces.splice(index, 1);
                }
                localStorage.setItem('favouritePlaces', JSON.stringify(state.favouritePlaces));
            } else {
                // add to favourite
                let updatedFavouritePlaces = [...JSON.parse(localStorage.getItem('favouritePlaces')) || [], action.payload.pk];
                localStorage.setItem('favouritePlaces', JSON.stringify(updatedFavouritePlaces));
                state.favouritePlaces = updatedFavouritePlaces;
            }
        },
    },
})

export const {setSelectedPlace, setSelectedPlacePk, setPlaces, addFavoritePlace} = placeSlice.actions;
export default placeSlice.reducer;
