export function getJsonObject(obj: any): {isJson: boolean, value: string} {
    const nonObjectValue = {isJson: false, value: obj === undefined ? 'null' : obj.toString()};
    if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
        return nonObjectValue;
    }
    try {
        return {isJson: true, value: JSON.stringify(obj)}
    } catch (error) {
        return nonObjectValue;
    }
}

export function log(title: string, obj: any, module: string = '', level = 1, verbose = false) {
    const tab = '  '.repeat(level-1);
    const {isJson, value} = getJsonObject(obj);
    if (isJson) {
        obj = value;
    }
    const message = `[info][${module.padStart(20,' ')}] | ${tab} ${title} ${obj}`;
    switch (level) {
        case 1: console.info(message); break;
        // case 2: console.trace(message); break;
        // case 3: console.debug(message); break;
        // case 4: console.trace(message); break;
        // case 5: console.table(message); break;
        default: ;
    }

    if (verbose) {
    }
}
