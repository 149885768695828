import {Link, NavLink, useSearchParams} from "react-router-dom";
import React from "react";

export function LinkWithSearchParams(props: any) {
    const [searchParams] = useSearchParams();
    const target = `${props.to}?${searchParams}`;
    return (
        <NavLink to={target} className={({ isActive }) => (isActive ? 'bg-slate-100/90 text-sky-600' : '')}>{props.children}</NavLink>
    )
}

export function LinkToPlaceListWithText() {
    return <LinkWithSearchParams to={'/places'}><div className='text-slate-600 w-full text-left'>« powrót do listy miejsc</div></LinkWithSearchParams>
}

export function LinkToPlaceList(props: any) {
    return <LinkWithSearchParams to={'/places'}>{props.children}</LinkWithSearchParams>
}

export function LinkToPlaceDetails(props: any) {
    return <LinkWithSearchParams to={`/places/${props.place.pk}`}>{props.children}</LinkWithSearchParams>
}

export function LinkToSearch(props: any) {
    return <LinkWithSearchParams to={`/search`}>{props.children}</LinkWithSearchParams>
}

export function LinkToOther(props: any) {
    return <LinkWithSearchParams to={`/settings`}>{props.children}</LinkWithSearchParams>
}

