import {isValidPlaceForMap} from "../../data/api";
import {log} from "../../utils/logs";
import {Marker, Tooltip} from "react-leaflet";
import {blueIcon, violetIcon} from "../Icons";
import {useLoaderData, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setSelectedPlace, setSelectedPlacePk} from "../../redux/place-slice";

export default function PlaceMarker({place}: any) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {searchParams}: any = useLoaderData();

    const selectedPlace = useAppSelector(state => state.place.selectedPlace);

    const isValidPlace = isValidPlaceForMap(place);
    log('Added Marker', {pk: place.pk, name: place.name, valid: isValidPlace, selectedPlace: selectedPlace || 'none'}, 'Marker', 1, true);

    return (
        isValidPlace ? (<div>
            <Marker key={place.pk} position={[place.latitude, place.longitude]} attribution={place.pk}
                    icon={(selectedPlace && place.pk === selectedPlace.pk) ? violetIcon : blueIcon }
                        eventHandlers={{
                            click: (e) => {
                                dispatch(setSelectedPlace(place));
                                navigate(`/places/${place.pk}?${searchParams}`);

                            },
                        }}
            >
                <Tooltip>{place.name}</Tooltip>
            </Marker>
        </div>) : (<></>)

    )
}

