import {useMap} from "react-leaflet";
import {useEffect} from "react";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.js";
import {setCurrentLocation, setIsGpsPositionDetected} from "../../redux/map-slice";
import {useAppDispatch} from "../../redux/hooks";
import {log} from "../../utils/logs";

export function FlyToGpsPositionController(): any {
    const verbose = true;
    const module = 'FlyToGpsPositionController';
    log(`starting`, '', module, 1, verbose);

    const map = useMap();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!map) return;
        const button = L.easyButton("fa-solid fa-location-crosshairs fa-xl", () => {
            map.locate().on("locationfound", function (e) {
                log(`event locationfound fired -> dispatching setCurrentLocation`, e.latlng, module, 1, verbose);
                dispatch(setCurrentLocation([e.latlng.lat, e.latlng.lng]));
                dispatch(setIsGpsPositionDetected(true));
                log(`flying map to:`, e.latlng, module, 1, verbose);
                map.flyTo(e.latlng, map.getZoom());
            });
        }).addTo(map);
        _fixEasyButtonSize(button);
    }, [map]);
}

function _fixEasyButtonSize(button: any) {
    var buttonElement = button.button;
    buttonElement.style.width = "auto";
    buttonElement.style.height = "auto";
    buttonElement.style.minWidth = "29px";
    buttonElement.style.minHeight = "29px";
}
