import {Form, useLoaderData, useSubmit, useNavigation} from "react-router-dom";
import {Place, Places} from "../../data/api";
import {LinkToPlaceDetails} from "../navigation/LinkWithSearchParams";
// import {IoArrowForwardCircle} from "react-icons/io5";
import React, {useEffect} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleChevronRight} from "@fortawesome/free-solid-svg-icons/faCircleChevronRight";


export function SearchPlaces() {
    const {places, q} = useLoaderData() as { places: Places, q: string };

    useEffect(() => {
        (document.getElementById('q') as HTMLInputElement).value = q;
    }, [q])

    const navigation = useNavigation();
    const searching =
        navigation.location &&
        new URLSearchParams(navigation.location.search).has(
            "q"
        );
    return (
        <SearchSection q={q} places={places}/>
    )
}

function SearchSection({q, places}: any) {
    const submit = useSubmit();


    return (
        // source: https://flowbite.com/blocks/application/table-headers/#default-table-header
        <section className="bg-gray-50 dark:bg-gray-900 min-h-[calc(100svh)] flex items-top">
            <div className="px-4 mx-auto w-full">

                {/*start*/}
                <div className="relative bg-white shadow-md dark:bg-gray-800 rounded-lg mt-4">
                    <div
                        className="flex flex-col items-center justify-between p-4 space-y-3">
                        <div className="w-full">
                            <Form id='search-form' role='search' className="flex items-center">
                                <label htmlFor="simple-search" className="sr-only">Wyszukaj</label>

                                <div className="relative w-full">
                                    <div
                                        className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                             fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </div>
                                    <input
                                        id="q"
                                        name="q"
                                        type="search"
                                        aria-label="Wyszukiwanie"
                                        placeholder="Wpisz czego szukasz"
                                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        required={false}
                                        onChange={(event) => {
                                            setTimeout(
                                                submit.bind(null, event.currentTarget.form)
                                                , 3000);

                                        }}
                                        defaultValue={q}
                                    >
                                    </input>
                                </div>
                            </Form>
                        </div>
                        <div
                            className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2">
                            <div className="flex items-center w-full space-x-3">
                                {/*<button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown"*/}
                                {/*        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"*/}
                                {/*        type="button">*/}
                                {/*    <svg className="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"*/}
                                {/*         xmlns="http://www.w3.org/2000/svg" aria-hidden="true">*/}
                                {/*        <path clipRule="evenodd"  fillRule="evenodd"*/}
                                {/*              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/>*/}
                                {/*    </svg>*/}
                                {/*    Actions*/}
                                {/*</button>*/}
                                {/*<div id="actionsDropdown"*/}
                                {/*     className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">*/}
                                {/*    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200"*/}
                                {/*        aria-labelledby="actionsDropdownButton">*/}
                                {/*        <li>*/}
                                {/*            <a href="#"*/}
                                {/*               className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass*/}
                                {/*                Edit</a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*    <div className="py-1">*/}
                                {/*        <a href="#"*/}
                                {/*           className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete*/}
                                {/*            all</a>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<button id="filterDropdownButton" data-dropdown-toggle="filterDropdown"*/}
                                {/*        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"*/}
                                {/*        type="button">*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"*/}
                                {/*         className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">*/}
                                {/*        <path fillRule="evenodd"*/}
                                {/*              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"*/}
                                {/*              clipRule="evenodd"/>*/}
                                {/*    </svg>*/}
                                {/*    Filtry*/}
                                {/*    <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"*/}
                                {/*         xmlns="http://www.w3.org/2000/svg" aria-hidden="true">*/}
                                {/*        <path clipRule="evenodd" fillRule="evenodd"*/}
                                {/*              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/>*/}
                                {/*    </svg>*/}
                                {/*</button>*/}

                                {/*dropdown menu*/}
                                {/*<div id="filterDropdown"*/}
                                {/*     className="z-10 hidden w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">*/}
                                {/*    <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">*/}
                                {/*        Kategorie*/}
                                {/*    </h6>*/}
                                {/*    <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">*/}
                                {/*        <li className="flex items-center">*/}
                                {/*            <input id="apple" type="checkbox" value=""*/}
                                {/*                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>*/}
                                {/*            <label htmlFor="apple"*/}
                                {/*                   className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">*/}
                                {/*                Sala zabaw*/}
                                {/*            </label>*/}
                                {/*        </li>*/}
                                {/*        <li className="flex items-center">*/}
                                {/*            <input id="fitbit" type="checkbox" value=""*/}
                                {/*                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>*/}
                                {/*            <label htmlFor="fitbit"*/}
                                {/*                   className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">*/}
                                {/*                Plac zabaw*/}
                                {/*            </label>*/}
                                {/*        </li>*/}
                                {/*        <li className="flex items-center">*/}
                                {/*            <input id="dell" type="checkbox" value=""*/}
                                {/*                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>*/}
                                {/*            <label htmlFor="dell"*/}
                                {/*                   className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">*/}
                                {/*                Klubokawiarnia*/}
                                {/*            </label>*/}
                                {/*        </li>*/}
                                {/*        <li className="flex items-center">*/}
                                {/*            <input id="asus" type="checkbox" value=""*/}
                                {/*                   className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>*/}
                                {/*            <label htmlFor="asus"*/}
                                {/*                   className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">*/}
                                {/*                Zewnętrzna atrakcja*/}
                                {/*            </label>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}


                            </div>
                        </div>
                    </div>
                </div>

                {/*search results*/}
                {
                    places.length ?
                        <>
                            <div id='separator' className='mt-4'>pasujące wyniki: {places.length}</div>
                            <div className="relative bg-white shadow-md dark:bg-gray-800 rounded-lg mt-1">
                                <div className="flex flex-col p-4 space-y-1 text-left">
                                    {places ? places.map((place: Place) => (
                                                <LinkToPlaceDetails place={place} key={place.pk}>
                                                    <div className='flex flex-row h-6 items-center'>
                                                        <FontAwesomeIcon icon={faCircleChevronRight} size={'sm'}/>
                                                        <div className='ml-1 truncate'>{place.name}</div>
                                                    </div>
                                                </LinkToPlaceDetails>
                                            )
                                        )
                                        : <div>brak wyników wyszukiwania</div>}
                                </div>
                            </div>
                        </>

                        : (
                            <>
                                <div id='separator' className='mt-4'>Niestety nie znalazłem pasujących wyników.</div>
                                <div id='separator' className='mt-2 text-sky-800'>Spróbuj zwiększyć obszar na mapie.</div>
                            </>
                        )
                }

            </div>
        </section>
    )
}
