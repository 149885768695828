import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import placeListLoader from "./loaders/placeListLoader";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import {MainLayout} from './components/page/MainLayout';
import {ErrorPage} from "./components/error/ErrorPage";
import {PlaceList} from "./components/places/PlaceList";
import PlaceDetails from "./routes/placeDetails";
import {placeDetailsLoader} from "./loaders/placeDetailsLoader";
import MapApiCoverage from "./components/map/MapApiCoverage";
import {SearchPlaces} from "./components/search/SearchPlaces";
import {SettingsPage} from "./components/settings/SettingsPage";

const router = createBrowserRouter([
    {
        path: "/",
        loader: placeListLoader, // loader for map
        element: <MainLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <PlaceList/>,
                loader: placeListLoader,   // loader for place list
            },
            {
                path: 'places',
                element: <PlaceList/>,
                loader: placeListLoader,   // loader for place list
            },
            {
                path: 'places/:placeId',
                element: <PlaceDetails/>,
                loader: placeDetailsLoader,
            },
            {
                path: 'search',
                element: <SearchPlaces/>,
                loader: placeListLoader,
            },
            {
                path: 'map-api-coverage',
                element: <MapApiCoverage />,
            },
            {
                path: 'settings',
                element: <SettingsPage />,
            }
        ]
    },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
)
