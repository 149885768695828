import {matchSorter} from "match-sorter";
import {log} from "../utils/logs";
import {makeApiCall} from "../loaders/utils";


export interface Place {
    pk: number,
    name?: string,
    latitude?: number,
    longitude?: number,
    images?: string[],
}

export type Places = Place[];

export function isValidPlace(place: Place) {
    const verbose = true;
    log(`isPlace`, place, 'isPlace', 2, verbose);
    if ('pk' in place) {
        log(`pk in place`, true, 'isPlace', 3, verbose);
        return true;
    } else {
        log(`check place.pk exists:`, false, 'isPlace', 3, verbose);
        return false
    }
}

export function isValidPlaceForMap(place: Place) {
    const verbose = false;
    let result = false;
    log(`isValidPlaceForMap`, place, 'isValidPlaceForMap', 2, verbose);
    (place.latitude && place.longitude) ? result = true : result = false;
    log(`check if place has pk, lat and lng: `, result, 'isValidPlaceForMap', 3, verbose);
    return result;
}

export async function getPlaceList(filter: any) {
    const verbose = true;
    const module = 'getPlaceList';

    log(`starting`, '', module, 1, verbose);

    const {q, bounds} = filter;

    // filter places on server side
    let baseUrl = `/api/places`;
    const urlParams = new URLSearchParams(filter).toString();
    let urlWithParams = `${baseUrl}?${urlParams}`
    log(`urlWithParams`, urlWithParams, module, 2, verbose);
    const request = await makeApiCall(urlWithParams);

    let places = [];
    try {
        places = await request.json();
        log(`places`, places, module, 2, verbose);
        log(`places.length`, places.length, module, 2, verbose);
        log(`places[0]`, places[0], module, 2, verbose);
    } catch (e) {
        log(`Error`, e, module, 1, verbose);
        log(`Error`, e.toString(), module, 1, verbose);
    }

    // add places filtering on client side
    log(`adding places filtering on client side`, '', module, 1, verbose);
    if (!places) places = [];
    if (q) {
        places = matchSorter(places, q, {
            keys: ["name", "description", "address", "phone", "website", "facebook", "tags", "latitude", "longitude", "gmaps", "images"],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
        });
    }
    log(`(after filtering) places`, places, module, 2, verbose);
    log(`(after filtering) places.length`, places.length, module, 2, verbose);
    log(`(after filtering) places[0]`, places[0], module, 2, verbose);
    return places;
}


export async function getPlaceDetails(id: any, verbose = false): Promise<Place> {
    log(`starting getPlace with id`, id, 'getPlaceDetails', 1, verbose);

    let baseUrl = `/api/places`;
    log(`baseUrl`, baseUrl, 'getPlace', 2, verbose);

    let urlWithParams = `${baseUrl}/${id}`
    log(`urlWithParams`, urlWithParams, 'getPlaceDetails', 2, verbose);

    let place: Place;
    try {
        const request = await makeApiCall(urlWithParams);
        log(`request`, request.toString(), 'getPlaceDetails', 2, verbose);
        if (!request.ok) {
            log(`request is invalid`, request.status, 'getPlaceDetails', 2, verbose);
            throw new Error(`response was not successful - status ${request.status}`)
        }
        place = await request.json();
        log(`request JSON results`, place, 'getPlaceDetails', 3, verbose);
    } catch (e) {
        log(`ERROR`, e.toString(), 'getPlaceDetails', 3, verbose);
        return {} as Place;
    }
    return place;
}

export async function addPlaceRecommendation(query?: any) {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query)
    };
    try {
        return await (await fetch('/api/recommendations/', settings)).json();
    } catch (e) {
        console.log(e);
    }
}
