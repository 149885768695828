import {useAppDispatch} from "../redux/hooks";
import {toggleUiAddRecommendation} from "../redux/map-slice";
import React from "react";
import {LinkToOther, LinkToPlaceList, LinkToSearch} from "./navigation/LinkWithSearchParams";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons/faSquarePlus";
import { faSliders } from "@fortawesome/free-solid-svg-icons/faSliders";

export function MenuButtonItem(props: any) {
    return (
        <div className='flex justify-center items-center hover:bg-gray-200 h-full'>
            {props.children}
        </div>
    )
}

export function Menu() {
    const dispatch = useAppDispatch();
    const toggleAddRecommendation = () => dispatch(toggleUiAddRecommendation());

    const iconSize = 'xl';

    return (
        <div id='menu-bottom-component' className='h-24 w-full border-t border-gray-200'>
            <div id='menu-bottom-categories'
                 className="h-10 grid max-w-xs grid-cols-3 gap-1 p-1 mx-auto my-1 bg-gray-100 rounded-lg"
                 role="group">
                <button type="button"
                        className="px-2 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 rounded-lg">
                    Nowe
                </button>
                <button type="button"
                        className="px-2 py-1.5 text-xs font-medium text-white bg-gray-900 rounded-lg">
                    Wszystkie
                </button>
                <button type="button"
                        className="px-2 py-1.5 text-xs font-medium text-gray-900 hover:bg-gray-200 rounded-lg">
                    Popularne
                </button>
            </div>

            <div id='menu-bottom-buttons' className="grid grid-cols-5 justify-center h-12 max-w-sm">
                <LinkToPlaceList><MenuButtonItem><FontAwesomeIcon icon={faHome} size={iconSize} /></MenuButtonItem></LinkToPlaceList>
                <LinkToOther><MenuButtonItem><FontAwesomeIcon icon={faStar} size={iconSize} /></MenuButtonItem></LinkToOther>
                <LinkToSearch><MenuButtonItem><FontAwesomeIcon icon={faMagnifyingGlass} size={iconSize} /></MenuButtonItem></LinkToSearch>
                <LinkToOther><MenuButtonItem><FontAwesomeIcon icon={faSquarePlus} size={iconSize} /></MenuButtonItem></LinkToOther>
                <LinkToOther><MenuButtonItem><FontAwesomeIcon icon={faSliders} size={iconSize} /></MenuButtonItem></LinkToOther>
            </div>
        </div>
    )
}
