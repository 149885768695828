import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Map, LatLngExpression} from "leaflet";
import {log} from "../utils/logs";
import {Place, Places} from "../data/api";

export interface HoveredPlace {
    pk: number,
    location: LatLngExpression
}

export type MapBounds = [[number, number], [number, number]];

export interface MapState {
    currentLocation: LatLngExpression;
    selectedPlace: number | undefined;
    selectedPlaceZoom: number;
    isGpsPositionDetected: boolean;
    mapInstance: Map | undefined;
    zoom: number;
    center: [number, number];
    bounds: MapBounds;
    places: Places | undefined;
    hoveredPlace: HoveredPlace | undefined;
    favouritePlaces: number[];
    uiSearchActive: boolean;
    uiAddRecommendationActive: boolean;

}

const initialState: MapState = {
    currentLocation: [51.1180274, 17.0376975],
    selectedPlace: undefined,
    selectedPlaceZoom: 13,
    mapInstance: undefined,
    isGpsPositionDetected: false,
    places: undefined,
    hoveredPlace: undefined,
    favouritePlaces: JSON.parse(localStorage.getItem('favouritePlaces')) || [],
    uiSearchActive: false,
    uiAddRecommendationActive: false,
    zoom: 13,
    center: [51.1180274, 17.0376975],
    bounds: [[51.0344586455438, 16.761167049407963], [51.199972943194496, 17.288510799407963]]
}

const mapSlice = createSlice({
    name: 'map',
    initialState: initialState,
    reducers: {
        setCurrentLocation(state, action: PayloadAction<LatLngExpression>) {
            state.currentLocation = action.payload;
        },
        setIsGpsPositionDetected(state, action: PayloadAction<boolean>) {
            state.isGpsPositionDetected = action.payload;
        },
        setMap(state, action: PayloadAction<Map>) {
            state.mapInstance = action.payload;
        },
        setPlaces(state, action: PayloadAction<Places>) {
            state.places = action.payload;
        },
        setHoveredPlacePk(state, action: PayloadAction<HoveredPlace | undefined>) {
            state.hoveredPlace = action.payload;
            document.getElementById(`place-item-wrapper-${state.hoveredPlace.pk}`).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        },
        addFavoritePlace(state, action: PayloadAction<Place>) {
            if (state.favouritePlaces?.includes(action.payload.pk)) {
                // place is already on list so remove from favourite
                const index = state.favouritePlaces.indexOf(action.payload.pk);
                if (index !== -1) {
                    state.favouritePlaces.splice(index, 1);
                }
                localStorage.setItem('favouritePlaces', JSON.stringify(state.favouritePlaces));
            } else {
                // add to favourite
                let updatedFavouritePlaces = [...JSON.parse(localStorage.getItem('favouritePlaces')) || [], action.payload.pk];
                localStorage.setItem('favouritePlaces', JSON.stringify(updatedFavouritePlaces));
                state.favouritePlaces = updatedFavouritePlaces;
            }
        },
        toggleUiSearchPlace(state) {
            state.uiSearchActive = !state.uiSearchActive;
        },
        toggleUiAddRecommendation(state) {
            state.uiAddRecommendationActive = !state.uiAddRecommendationActive;
        },
        setZoom: (state, action: PayloadAction<number>) => {
            console.log(`   Reducer: setZoom reducer - zooming to ${action.payload}`)
            state.zoom = action.payload
        },
        setBounds: (state, action: PayloadAction<MapBounds>) => {
            console.log(`   Reducer: setBounds - setting to ${action.payload}`)
            state.bounds = action.payload;
        },
        setCenter: (state, action: PayloadAction<[number, number]>) => {
            console.log(`   Reducer: setCenter - setting to ${action.payload}`)
            state.center = action.payload;
        }

    },
})

export const {
    setCurrentLocation,
    setIsGpsPositionDetected,
    setCenter,
    setBounds,

    // todo: possible to remove - not used anymore
    setMap,
    setHoveredPlacePk,
    addFavoritePlace,
    toggleUiSearchPlace,
    toggleUiAddRecommendation,
    setZoom
} = mapSlice.actions;
export default mapSlice.reducer;
