import {useMap, useMapEvents} from "react-leaflet";
import "leaflet-easybutton/src/easy-button.js";
import {log} from "../../utils/logs";
import {useAppDispatch} from "../../redux/hooks";
import {MapBounds, setBounds} from "../../redux/map-slice";
import {useSearchParams} from "react-router-dom";

export function MapBoundsController(): any {
    const verbose = true;
    const module = 'MapBoundsController';
    log(`starting`, '', module, 1, verbose);

    const map = useMap();
    // const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useMapEvents({
        moveend(e) {
            log(`useMapEvents event: moveend`, map.getBounds().toBBoxString(), module, 1, verbose);


            const bounds = e.target.getBounds();
            const boundNumbers: MapBounds = [
                [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
                [bounds.getNorthEast().lat, bounds.getNorthEast().lng]
            ]

            log(`dispatching setBounds(boundNumbers): `, boundNumbers, module, 2, verbose);
            // dispatch(setBounds(boundNumbers));
            // equivalent to API definition
            // bound_sw_lat = convert_to_float(bound_values[0])
            // bound_sw_lng = convert_to_float(bound_values[1])
            // bound_ne_lat = convert_to_float(bound_values[2])
            // bound_ne_lng = convert_to_float(bound_values[3])
            const precisionDigits = 4;
            const southWestLat = bounds.getSouthWest().lat.toFixed(precisionDigits).toString()
            const southWestLng = bounds.getSouthWest().lng.toFixed(precisionDigits).toString()
            const northEastLat = bounds.getNorthEast().lat.toFixed(precisionDigits).toString()
            const northEastLng = bounds.getNorthEast().lng.toFixed(precisionDigits).toString()
            searchParams.set('bounds', `${southWestLat},${southWestLng},${northEastLat},${northEastLng}`);
            setSearchParams(searchParams)
        }
    })
    return null
}
