(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("L"));
	else if(typeof define === 'function' && define.amd)
		define(["L"], factory);
	else if(typeof exports === 'object')
		exports["MyLibrary"] = factory(require("L"));
	else
		root["MyLibrary"] = root["MyLibrary"] || {}, root["MyLibrary"]["main"] = factory(root["L"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__6787__) => {
return 