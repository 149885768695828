import {useMap, useMapEvents} from "react-leaflet";
import "leaflet-easybutton/src/easy-button.js";
import {log} from "../../utils/logs";

import L, {LatLng, LatLngExpression, LatLngTuple} from "leaflet";
import {setCenter} from "../../redux/map-slice";

function generalDolnoslaskieView(map: any) {
    const baseDensity = 0.076;
    const radius = 5000;

    const multiplier = 10;
    // const density = 0.02;
    // const radius = 1000;
    const center: LatLngTuple = [50.5909, 16.7];

    const results: any = [];

    for (let xMultiplier = 1; xMultiplier <= multiplier; xMultiplier++) {


        for (let yMultiplier = 1; yMultiplier <= multiplier; yMultiplier++) {
            const density_x = baseDensity * xMultiplier;
            const density_y = baseDensity * yMultiplier;

            const point1 = [center[0], center[1]] as LatLngExpression;
            const point2 = [center[0] + density_x, center[1]] as LatLngExpression;
            const point3 = [center[0] + density_x, center[1] + density_y] as LatLngExpression;
            const point4 = [center[0], center[1] + density_y] as LatLngExpression;

            const points = [];
            // points.push(point1);
            // points.push(point2);

            // if ((xMultiplier == 7) && ((yMultiplier >= 3) && (yMultiplier <= 5)))
            {
                points.push(point3);
            }
            // points.push(point4);


            points.forEach(point => {

                const circle = L.circle(point, {
                    radius: radius,
                    color: 'blue',
                    fillColor: 'green',
                    fillOpacity: 0.01,
                })
                    .bindTooltip(`${xMultiplier};${yMultiplier}`, {
                        permanent: true,
                        className: "durak_label",
                        direction: 'center',
                        offset: [0, 0]
                    })
                    .addTo(map);
                results.push(
                    {
                        pointLat: (point as LatLngTuple)[0],
                        pointLng: (point as LatLngTuple)[1],
                        xMultiplier: xMultiplier,
                        yMultiplier: yMultiplier,
                        baseDensity: baseDensity,
                        radius: radius,
                        multiplier: multiplier,
                        centerLat: center[0],
                        centerLng: center[1],
                        label: `${xMultiplier};${yMultiplier}`
                    }
                )

            })
        }
    }

}

function generalWroclawView(map: any, center: LatLngTuple, color: string) {
    const baseDensity = 0.030;
    const radius = 2000;

    const multiplier = 3;
    // const density = 0.02;
    // const radius = 1000;

    const results: any = [];

    for (let xMultiplier = 1; xMultiplier <= multiplier; xMultiplier++) {


        for (let yMultiplier = 1; yMultiplier <= multiplier; yMultiplier++) {
            const density_x = baseDensity * xMultiplier;
            const density_y = baseDensity * yMultiplier;

            const point1 = [center[0], center[1]] as LatLngExpression;
            const point2 = [center[0] + density_x, center[1]] as LatLngExpression;
            const point3 = [center[0] + density_x, center[1] + density_y] as LatLngExpression;
            const point4 = [center[0], center[1] + density_y] as LatLngExpression;

            const points = [];
            // points.push(point1);
            // points.push(point2);
            points.push(point3);
            // points.push(point4);


            points.forEach(point => {
                const circle = L.circle(point, {
                    radius: radius,
                    color: color,
                    fillColor: 'green',
                    fillOpacity: 0.01,
                })
                    .bindTooltip(`${xMultiplier};${yMultiplier}`, {
                        permanent: true,
                        className: "durak_label",
                        direction: 'center',
                        offset: [0, 0]
                    })
                    .addTo(map);
                results.push(
                    {
                        pointLat: (point as LatLngTuple)[0],
                        pointLng: (point as LatLngTuple)[1],
                        xMultiplier: xMultiplier,
                        yMultiplier: yMultiplier,
                        baseDensity: baseDensity,
                        radius: radius,
                        multiplier: multiplier,
                        centerLat: center[0],
                        centerLng: center[1],
                        label: `${xMultiplier};${yMultiplier}`
                    }
                )

            })
        }
    }
    console.log(results);
}
function detailedWroclawView(map: any, center: LatLngTuple, color: string) {
    const baseDensity = 0.013;
    const radius = 700;

    const multiplier = 6;
    // const density = 0.02;
    // const radius = 1000;

    const results: any = [];

    for (let xMultiplier = 1; xMultiplier <= multiplier; xMultiplier++) {


        for (let yMultiplier = 1; yMultiplier <= multiplier; yMultiplier++) {
            const density_x = baseDensity * xMultiplier;
            const density_y = baseDensity * yMultiplier;

            const point1 = [center[0], center[1]] as LatLngExpression;
            const point2 = [center[0] + density_x, center[1]] as LatLngExpression;
            const point3 = [center[0] + density_x, center[1] + density_y] as LatLngExpression;
            const point4 = [center[0], center[1] + density_y] as LatLngExpression;

            const points = [];
            // points.push(point1);
            // points.push(point2);
            points.push(point3);
            // points.push(point4);


            points.forEach(point => {
                const circle = L.circle(point, {
                    radius: radius,
                    color: color,
                    fillColor: 'green',
                    fillOpacity: 0.01,
                })
                    .bindTooltip(`${xMultiplier};${yMultiplier}`, {
                        permanent: true,
                        className: "durak_label",
                        direction: 'center',
                        offset: [0, 0]
                    })
                    .addTo(map);
                results.push(
                    {
                        pointLat: (point as LatLngTuple)[0],
                        pointLng: (point as LatLngTuple)[1],
                        xMultiplier: xMultiplier,
                        yMultiplier: yMultiplier,
                        baseDensity: baseDensity,
                        radius: radius,
                        multiplier: multiplier,
                        centerLat: center[0],
                        centerLng: center[1],
                        label: `${xMultiplier};${yMultiplier}`
                    }
                )

            })
        }
    }
    console.log(results);
}

export function MapApiCoverageController(): any {
    const verbose = true;
    const module = 'MapApiCoverageController';
    log(`starting`, '', module, 1, verbose);

    const map = useMap();
    // const resultsRed = generalWroclawView(map, [51.0610994, 17.0344034], 'red');
    // const resultsGreen = generalWroclawView(map, [51.0610994, 16.8444034], 'green');
    // const resultsYellow = generalWroclawView(map, [51.0610994, 16.9544034], 'yellow');
    const resultsBlue = detailedWroclawView(map, [51.0630994, 16.9944034], 'purple');
    // const results = generalDolnoslaskieView(map);
    // console.log(results);
    return null;
}


