import {useMap} from "react-leaflet";
import {useEffect} from "react";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.js";
import {setCurrentLocation} from "../../redux/map-slice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {log} from "../../utils/logs";
import {isValidPlace, Place, Places} from "../../data/api";
import {useLoaderData} from "react-router-dom";

export function MoveToSelectedMarkerComponent(): any {
    const verbose = true;
    const module = 'ShowSelectedMarkerComponent';
    log(`starting`, '', module, 1, verbose);

    const map = useMap();
    const {places} = useLoaderData() as { places: Places };
    const selectedPlaceZoom = useAppSelector(state => state.map.selectedPlaceZoom);
    const selectedPlacePk: number = useAppSelector(state => state.place.selectedPlacePk);

    useEffect(() => {
        log('Places', places, 'Map', 2, true);
        const selectedPlace: Place = places.find((place: Place) => place.pk === selectedPlacePk);
        log('selectedPlace', selectedPlace, 'Map', 2, true);

        // disable fly to selected place
        // if(selectedPlace && isValidPlace(selectedPlace)) {
        //     map.flyTo([selectedPlace.latitude, selectedPlace.longitude], selectedPlaceZoom);
        // }
    }, [selectedPlacePk]);
}
