import { LatLngExpression } from "leaflet";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentLocation, setHoveredPlacePk } from "../../redux/map-slice";

export function PlaceItemWrapper(props: any) {
    const dispatch = useAppDispatch();
    const hoveredPlace = useAppSelector(state => state.map.hoveredPlace)
    const hoveredPlacePk = hoveredPlace?.pk;

    let selectedItemStyle;
    if (hoveredPlacePk && hoveredPlacePk == props.place.pk) {
        selectedItemStyle = 'bg-gradient-to-r from-blue-500/30 to-blue-600/30 shadow-zinc-500'
    }
    const baseClassName = `shadow-md cursor-pointer ${selectedItemStyle}`
    return (
        <div id={`place-item-wrapper-${props.place.pk}`} className={baseClassName}
             onMouseDown={() => {
                 const hoveredPlaceLocation = [props.place.latitude, props.place.longitude] as LatLngExpression;
                 dispatch(setHoveredPlacePk({pk: props.place.pk, location: hoveredPlaceLocation}));
                 dispatch(setCurrentLocation(hoveredPlaceLocation));
             }}
        >
            {props.children}
        </div>
    )
}
