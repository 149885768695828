const apiAuthToken = 'Token f485f911ae9fff9d54e07ff911e15821238831a5';

export async function makeApiCall(url: string) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `${apiAuthToken}`,
        },
    });
}
