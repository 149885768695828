// import {useMap, useMapEvents} from "react-leaflet";
// import {useLocation, useParams, useSearchParams} from "react-router-dom";
// import {useEffect, useState} from "react";
// import {Geocoder, geocoders} from "leaflet-control-geocoder";
// import "leaflet-easybutton/src/easy-button.js";
// import {log} from "../../utils/logs";
// import {useAppDispatch, useAppSelector} from "../../redux/hooks";
// import {MapBounds, setBounds, setSelectedPlace} from "../../redux/map-slice";
// import L from "leaflet";
//
import {log} from "../../utils/logs";
import {useMap} from "react-leaflet";
import {useEffect} from "react";
import {useLoaderData, useLocation, useParams} from "react-router-dom";
import {Places} from "../../data/api";
import {setSelectedPlacePk} from "../../redux/place-slice";
import {useAppDispatch} from "../../redux/hooks";
import {setCenter} from "../../redux/map-slice";

export function MapCenterController(): any {
    const verbose = true;
    const module = 'MapCenterController';
    log(`starting`, '', module, 1, verbose);

    // const map = useMap();
    // const location = useLocation();
    // const dispatch = useAppDispatch();
    // const params = useParams();
    //
    // const {places} = useLoaderData() as { places: Places };
    //
    //
    // log(`place`, places, module, 1, verbose);
    //
    // const {placeId} = params;
    // const placePk: number = parseInt(placeId);
    // log(`placePk`, placePk, module, 1, verbose);
    //
    // if(placePk) {
    //     const selectedPlace = places.filter(place => place.pk === placePk);
    //     log(`selectedPlace`, selectedPlace[0], module, 1, verbose);
    //     if(selectedPlace[0] && selectedPlace[0].latitude && selectedPlace[0].longitude)
    //     {
    //     dispatch(setCenter([selectedPlace[0].latitude, selectedPlace[0].longitude]));
    //     }
    //
    // }




    // const dispatch = useAppDispatch();
    // const bounds: [[number, number], [number, number]] = useAppSelector(state => state.map.bounds);
    // const location = useLocation();
    //
    // useEffect(() => {
    //     // log(`location changed`, 'ayz', module, 1, verbose);
    //     // map.fitBounds(L.latLngBounds(bounds))
    //
    // }, [location]);

    return null
}
