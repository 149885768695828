import React from "react";
import {Marker, Tooltip} from "react-leaflet";
import {currentLocationIcon} from "../Icons";
import {useAppSelector} from "../../redux/hooks";
import {log} from "../../utils/logs";

export default function CurrentPositionMarker() {
    const verbose = true;
    const module = 'CurrentPositionMarker';
    log(`starting`, '', module, 1, verbose);


    const currentPosition = useAppSelector(state => state.map.currentLocation);
    const isGpsPositionDetected = useAppSelector(state => state.map.isGpsPositionDetected);
    log(`currentPosition`, currentPosition, module, 1, verbose);
    log(`isGpsPositionDetected`, isGpsPositionDetected, module, 1, verbose);

    return (
        isGpsPositionDetected ?
            <Marker position={currentPosition} icon={currentLocationIcon}>
                <Tooltip >Jesteś tutaj</Tooltip>
            </Marker>
            : <></>
    )
}
