import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export function ErrorPage() {
    const error = useRouteError();
    let customMessage;

    if (isRouteErrorResponse(error)) {
        if (error.status === 401) {
            customMessage = 'You have no permissions for this site :(';
        }
        else if (error.status === 404) {
            customMessage = 'It seems that this page does not exists yet :(';
        }

        return (
            <div id="error-page">
                <h1>Oops! Status code: {error.status}</h1>
                <p>{error.statusText}</p>
                <div className='text-orange-500'>{customMessage}</div>
                {error.data?.message && (
                    <p>
                        <i>{error.data.message}</i>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof Error) {
        return (
            <div id="error-page">
                <h1>Oops! Unexpected Error</h1>
                <p>Something went wrong.</p>
                <p>
                    <i>{error.message}</i>
                </p>
            </div>
        );
    } else {
        return <></>;
    }
}
