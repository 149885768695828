import React, {SetStateAction, TouchEvent, useState} from 'react';
// import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs';
// import {RxDotFilled} from 'react-icons/rx';
import {log} from "../../utils/logs";
import {LinkToPlaceDetails} from "../navigation/LinkWithSearchParams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons/faCircleChevronRight";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons/faCircleChevronLeft";

export function PlaceSlider({place}: any) {
    const verbose = true;
    log(`starting for place pk`, place.pk, 'PlaceSlider', 1, verbose);

    let slides: { url: any; }[] = [];
    let xDown: number = null;
    let yDown: number = null;
    place.images.map((image: { images: any; }) => slides.push({url: image.images}))
    log(`slides for place:`, slides, 'PlaceSlider', 2, verbose);
    log(`slides.lenght:`, slides.length, 'PlaceSlider', 3, verbose);
    log(`slides[0]:`, (slides.length) ? slides[0] : '', 'PlaceSlider', 3, verbose);

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex: SetStateAction<number>) => {
        setCurrentIndex(slideIndex);
    };

    const currentImage = (slides && slides[currentIndex] && slides[currentIndex].url) ? slides[currentIndex].url : '';

    const handleTouchMove = (event: TouchEvent) => {
        if (!xDown || !yDown) return;
        var xUp = event.touches[0].clientX;
        var yUp = event.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                // left swipe
                nextSlide();
            } else {
                // right swipe
                prevSlide();
            }
        }
        // reset values
        xDown = null;
        yDown = null;
    };

    const handleTouchStart = (event: TouchEvent) => {
        xDown = event.touches[0].clientX;
        yDown = event.touches[0].clientY;
    }
    return (
        <div id='place-image-slider' className='w-full m-auto relative group cursor-default'>
            <div
                className='w-full h-64 rounded-t-lg bg-center bg-cover duration-500'
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
            >
                <img src={currentImage} loading='lazy' className='w-full object-cover h-full'/>
            </div>
            {/* Left Arrow */}
            <div
                className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                {/*<BsChevronCompactLeft onClick={prevSlide} size={30}/>*/}
                <FontAwesomeIcon icon={faCircleChevronLeft} size={'2xl'} onClick={prevSlide} />
            </div>
            {/* Center Go to details */}
            <div
                className='hidden group-hover:block absolute top-[50%] translate-x-1/2 right-1/2 top-1/2 translate-y-[-50%] text-2xl center-block rounded-full p-2 cursor-pointer'>
                <LinkToPlaceDetails place={place}>
                    <div onClick={prevSlide} className='w-48 h-36'/>
                </LinkToPlaceDetails>

            </div>
            {/* Right Arrow */}
            <div
                className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                {/*<BsChevronCompactRight onClick={nextSlide} size={30}/>*/}
                <FontAwesomeIcon icon={faCircleChevronRight} size={'2xl'} onClick={nextSlide} />
            </div>
            {/* dots */}
            <div className='absolute w-full flex bottom-1 justify-center py-2 overflow-hidden'>
                {slides.map((slide, slideIndex) => (
                    <div
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                        className={`text-5xl p-1 tracking-tighter font-black cursor-pointer ${slideIndex === currentIndex ? 'text-white text-opacity-90' : 'text-black text-opacity-50'}`}
                    >
                        •
                    </div>
                ))}
            </div>
        </div>
    );
}
