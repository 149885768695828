import {useMap, useMapEvents} from "react-leaflet";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Geocoder, geocoders} from "leaflet-control-geocoder";
import "leaflet-easybutton/src/easy-button.js";
import {log} from "../../utils/logs";
import {useAppDispatch} from "../../redux/hooks";


export function SearchLocationController(): any {
    const verbose = true;
    const module = 'SearchLocationController';
    log(`starting`, '', module, 1, verbose);

    const map = useMap();
    const [geocoder, setSetGeocoder] = useState<Geocoder | null>(null);

    useEffect(() => {
        // add search bar
        // todo: fix multiple search box for Geocoder
        // first remove all search bars
        const searchControls = document.getElementsByClassName('leaflet-control-geocoder')
        if (searchControls.length > 0) {
            for (let i = 0; i < searchControls.length; i++) {
                searchControls[i].remove()
            }
        }
        // add geocoder (search bar)
        if (!geocoder) {
            var geocoderObject = new Geocoder({
                geocoder: new geocoders.Nominatim({
                    geocodingQueryParams: {
                        'accept-language': 'pl',
                        'countrycodes': 'pl',
                    },
                }),
                position: 'bottomleft',
                collapsed: true,
                placeholder: 'wyszukaj lokalizację',
                showUniqueResult: true,
                showResultIcons: false,
                queryMinLength: 3,
                // todo: create different marker for location result
            }).addTo(map);
            setSetGeocoder(geocoderObject);
        }
    },[] )
    return null
}
