import React from "react";
import {PlaceItemWrapper} from "./PlaceItemWrapper";
import {PlaceSlider} from "./PlaceSlider";
import {useLoaderData} from "react-router-dom";
import {Places} from "../../data/api";
import {LinkToPlaceDetails} from "../navigation/LinkWithSearchParams";
import { getPlaceRatingValue } from "../../data/helpers";

export const PlaceList = () => {
    const {places, searchParams} = useLoaderData() as { places: Places, searchParams: any };
    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900 min-h-[calc(100svh)] flex items-top">
                <div className="px-4 py-2 mx-auto w-full">
                    <div id='place-list-wrapper'>
                        {
                            places ?
                                places.map((place: any) => (

                                        <div
                                            className="flex flex-col items-stretch justify-end flex-shrink-0 w-full py-1 space-y-2 w-full">
                                            <PlaceItem key={place.pk} place={place} searchParams={searchParams}/>
                                        </div>
                                    )
                                )
                                :
                                <div>Nie ma miejsc do wyświetlenia. Spróbuj zwiększyć obszar na mapie.</div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export const PlaceItem = ({place, searchParams}: any) => {
    return (
        <PlaceItemWrapper place={place}>
            <div id='place-item'
                 className="bg-white relative max-w-sm rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                {
                    place.images.length ? <PlaceSlider place={place}/> : <div id='default-picture'><img src={place.image} /></div>
                }
                <div id='place-description'>
                    <div className="p-4">
                        {/*place name*/}
                        <LinkToPlaceDetails place={place}>
                            <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">{place.name}</h5>
                        </LinkToPlaceDetails>

                        {/*place address*/}
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{place.address}</p>
                        <div id='icons'>
                            {/*favorite icon*/}
                            <div id='favorite' className='absolute bottom-2 right-2 text-amber-500'
                                //  onClick={() => addToFavoritePlaces(place)}
                            >
                            </div>
                        </div>

                        {/*rating*/}
                        <div id='item-rating'
                             className='absolute top-0 left-0 inline-block border bg-amber-500 text-white text-xs font-bold m-2 p-1 rounded-md'>
                            <span>★</span> {getPlaceRatingValue(place.rating)}
                        </div>
                    </div>
                </div>

                <div id='place-maps-icon'
                     className='absolute top-0 right-0 rounded-md p-2'>
                    <a href={place.gmaps} target="_blank" rel="noopener noreferrer">
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                            <image
                                href="https://upload.wikimedia.org/wikipedia/commons/3/39/Google_Maps_icon_%282015-2020%29.svg"
                                height="30" width="30"/>
                        </svg>
                    </a>
                </div>
            </div>
        </PlaceItemWrapper>
    )
}
